'use client';

import { theme } from '@/styles/theme';
import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider } from '@chakra-ui/react';
import { useToasts } from '@medsimples/design-system';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/locale/pt_BR';
import { type ReactNode, useState } from 'react';
import AuthProvider from './providers/auth_provider';
import FeatureFlagProvider from './providers/feature_flag_provider';

export function Providers({
  children,
}: {
  children: ReactNode;
}) {
  const toast = useToasts();
  // useState to avoid recreating a new client if React suspends
  const [queryClient] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (error) => {
          console.error(error);
          toast.errorToast(error.message);
        },
      }),
      defaultOptions: {
        queries: {
          retry: 3,
          staleTime: Number.POSITIVE_INFINITY,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider>
        <ChakraProvider theme={theme}>
          <ConfigProvider locale={ptBR}>
            <AuthProvider>
              <FeatureFlagProvider>{children}</FeatureFlagProvider>
            </AuthProvider>
          </ConfigProvider>
        </ChakraProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
}
